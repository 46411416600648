export const Checkmark = () => {
    return (
        <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.00001 10.1701L2.53001 6.70007C2.14002 6.31007 1.51001 6.31007 1.12001 6.70007C0.730015 7.09007 0.730015 7.72007 1.12001 8.11007L5.30001 12.2901C5.69001 12.6801 6.32001 12.6801 6.71001 12.2901L17.29 1.71007C17.68 1.32007 17.68 0.690068 17.29 0.300068C16.9 -0.0899316 16.27 -0.0899316 15.88 0.300068L6.00001 10.1701Z"
                fill="#09B46B"
                fillOpacity="0.8"
            />
        </svg>
    )
}
