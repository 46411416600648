import { identityService } from '@service/identity'
import { MePatchResponse, TouristData } from '@service/identity.types'
import { LogLabels, LogTypes } from '@util/enums'
import { AxiosError, AxiosResponse } from 'axios'
import { UseMutationOptions, useMutation } from 'react-query'
import { useLog } from './useLog'

export const useTourist = (
    options?: UseMutationOptions<Partial<AxiosResponse<MePatchResponse, any>>, unknown, TouristData, unknown>,
) => {
    const logger = useLog()

    return useMutation({
        mutationFn: (value: TouristData) => {
            return identityService.setTouristFlag(value)
        },
        onError: (error: unknown) => {
            const serverMsg: any = (error as AxiosError).response?.data || (error as AxiosError).message || ''
            logger(
                `Error during update is_tourist flag. ${JSON.stringify(serverMsg)}`,
                LogTypes.error,
                LogLabels.ProfilePage,
            )
        },
        ...options,
    })
}
