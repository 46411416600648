import { FC, useRef, useState } from 'react'
import { useClickOutside } from '@hooks/clickOutside'
import { LanguageGlobe } from './icons/LanguageGlobe'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { device } from '@util/responsive'
import { useLanguage } from '@hooks/useLanguage'
import { getLocaleDefinitionByUtsLanguageCode } from '@util/localization'
import { LanguageISOCode } from '@util/enums'
import {
    MobileDropDown,
    MobileDropDownComposedText,
    MobileDropDownContentContainer,
    MobileDropDownIconContainer,
    MobileDropdownItem,
    MobileDropDownItemContainer,
    MobileDropDownMenuText,
    NavIconAndLink,
} from '@global/styled'
import { ArrowIcon } from '@global/icons/Arrow'
import { useTranslation } from 'next-i18next'
import { Checkmark } from '@global/icons/Checkmark'

type LangSwitcherProps = {
    isDesktop?: boolean
    setIsMobileLanguageSwitcherOpen?: (isOpen: boolean) => void
}

const LangSwitcher: FC<LangSwitcherProps> = ({ isDesktop, setIsMobileLanguageSwitcherOpen }) =>
    isDesktop ? (
        <DesktopLangSwitcher />
    ) : (
        <MobileLangSwitcher setIsMobileLanguageSwitcherOpen={setIsMobileLanguageSwitcherOpen} />
    )

type MobileLangSwitcherProps = {
    setIsMobileLanguageSwitcherOpen?: (isOpen: boolean) => void
}

const MobileLangSwitcher: FC<MobileLangSwitcherProps> = ({ setIsMobileLanguageSwitcherOpen }) => {
    const { t } = useTranslation()
    const { locale } = useRouter()

    const { changeLanguageTo, supportedLanguages, currentLanguage } = useLanguage()

    const onLangItemClick = async (lang: LanguageISOCode) => {
        await changeLanguageTo(lang)
    }

    if (!locale) return null
    return (
        <MobileDropDown>
            <MobileDropDownContentContainer
                onClick={() => setIsMobileLanguageSwitcherOpen && setIsMobileLanguageSwitcherOpen(false)}
            >
                <MobileDropDownItemContainer>
                    <MobileDropdownItem>
                        <MobileDropDownIconContainer>
                            <ArrowIcon rotate={90} color="var(--primaryIconColor)" />
                        </MobileDropDownIconContainer>
                        <MobileDropDownMenuText>{t('menuItem.back', 'Back')}</MobileDropDownMenuText>
                    </MobileDropdownItem>
                </MobileDropDownItemContainer>
                {supportedLanguages.map((language) => {
                    const isCurrentlySelected = currentLanguage?.languageIsoCode === language.languageIsoCode
                    return (
                        <MobileDropDownItemContainer
                            key={language.name}
                            onClick={() => !isCurrentlySelected && onLangItemClick(language.languageIsoCode)}
                        >
                            <MobileDropdownItem>
                                <LanguageFlagIconItem>{language.languageFlag}</LanguageFlagIconItem>
                                {isCurrentlySelected ? (
                                    <MobileDropDownComposedText>
                                        <span>{t(`headerLanguage${language.name}`, language.name)}</span>
                                        <Checkmark />
                                    </MobileDropDownComposedText>
                                ) : (
                                    <MobileDropDownMenuText>
                                        {t(`headerLanguage${language.name}`, language.name)}
                                    </MobileDropDownMenuText>
                                )}
                            </MobileDropdownItem>
                        </MobileDropDownItemContainer>
                    )
                })}
            </MobileDropDownContentContainer>
        </MobileDropDown>
    )
}

const DesktopLangSwitcher: FC = () => {
    const { locale } = useRouter()
    const { t } = useTranslation()
    const [isOpen, setIsOpen] = useState(false)
    const langSelector = useRef(null)
    useClickOutside(langSelector, () => {
        setIsOpen(false)
    })

    const { changeLanguageTo, supportedLanguages, currentLanguage } = useLanguage()

    const onLangItemClick = async (lang: LanguageISOCode) => {
        await changeLanguageTo(lang)
        setIsOpen(false)
    }
    if (!locale) return null
    const currentLocale = getLocaleDefinitionByUtsLanguageCode(locale)?.name || ''
    return (
        <div ref={langSelector}>
            <NavIconAndLink
                onClick={() => {
                    setIsOpen(!isOpen)
                }}
            >
                <LanguageGlobe />
                <p>{t(`headerLanguage${currentLocale}`, currentLocale)}</p>
            </NavIconAndLink>
            {isOpen && supportedLanguages && (
                <DropDownMenu>
                    {supportedLanguages.map((language) => {
                        if (currentLanguage?.languageIsoCode === language.languageIsoCode) return null
                        return (
                            <LangItem key={locale} onClick={() => onLangItemClick(language.languageIsoCode)}>
                                {t(`headerLanguage${language.name}`, language.name)}
                            </LangItem>
                        )
                    })}
                </DropDownMenu>
            )}
        </div>
    )
}

const LangItem = styled.p`
    cursor: pointer;
    margin: 0;
`

const DropDownMenu = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    background-color: white;
    top: 0;
    border-radius: var(--borderRadius);
    position: absolute;
    padding: 16px;
    box-shadow: var(--boxShadow);
    z-index: 100;
    @media ${device.laptop} {
        z-index: 100;
    }
`

const LanguageFlagIconItem = styled(MobileDropDownIconContainer)`
    font-size: 25px;
`

export default LangSwitcher
