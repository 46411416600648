import { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { RequireDocument, Side, UploadedDocument, UploadedDocuments, VerificationStatus } from '@service/identity.types'
import { Booking, StatusType } from '@service/booking.types'
import { useAuth } from '@contexts/auth'
import { useMyBookings } from '@hooks/api/booking'
import { identityService } from '@service/identity'
import { useLocaleConfig } from '@contexts/config'
import { Country } from '@util/enums'
import { useTourist } from './useTourist'

export type VerificationDocuments = {
    identityFront: UploadedDocuments | null
    identityBack: UploadedDocuments | null
    driversLicenseFront: UploadedDocuments | null
    driversLicenseBack: UploadedDocuments | null
    internationalDriversLicenseBack: UploadedDocuments | null
    internationalDriversLicenseFront: UploadedDocuments | null
    passportFront: UploadedDocuments | null
    visaFront: UploadedDocuments | null
}

export const useVerification = (): {
    isUserVerified: boolean | undefined
    hasPendingBooking: boolean | undefined
    documents: VerificationDocuments | undefined
    verificationStatus: VerificationStatus | undefined
    isLoading: boolean
    bookings: Booking[]
    verificationStatusRefetch: () => void
    saveIsTouristFlag: (isTourist: boolean) => void
    requiredDocuments: RequireDocument | undefined
    getDocuments: (ignoreDeclined?: boolean) => VerificationDocuments
} => {
    const { country } = useLocaleConfig()
    const [hasPendingBooking, setHasPendingBooking] = useState<boolean | undefined>()
    const [isUserVerified, setIsUserVerified] = useState<boolean | undefined>()
    const [documents, setDocuments] = useState<VerificationDocuments>()
    const { user } = useAuth()
    const { mutate: updateTourist } = useTourist()

    const {
        data: verificationData,
        isLoading: verificationStatusLoading,
        refetch: verificationStatusRefetch,
    } = useQuery(['verificationStatus', user?.userId, country], () => identityService.getVerificationStatus(country), {
        refetchOnMount: 'always',
        enabled: !!user && !!country,
    })

    const { data: requiredDocumentsData } = useQuery(
        ['requiredDocuments', user?.userId, country],
        () => identityService.getRequiredDocuments(country),
        {
            refetchOnMount: 'always',
            enabled: !!user && !!country,
        },
    )

    const documentsArray = verificationData?.data?.uploadedDocuments

    const requiredDocuments = useMemo(() => {
        return requiredDocumentsData?.data?.requiredDocuments
    }, [requiredDocumentsData?.data?.requiredDocuments])

    const { data: bookings } = useMyBookings()

    const findDocuments = (
        listOfDocuments: UploadedDocuments[] | undefined,
        docType: string,
        docSide: Side,
        ignoreDeclined = true,
    ) => {
        return listOfDocuments?.find(({ status, type, side }) => {
            return (!ignoreDeclined || status !== 'DECLINED') && type === docType && side === docSide
        })
    }

    const saveIsTouristFlag = useCallback(
        async (isTourist: boolean) => {
            try {
                if (typeof user?.isTouristUae === 'undefined' || typeof !user?.isTouristKsa === 'undefined') return
                updateTourist({
                    isTouristUae: country === Country.UAE ? isTourist : user?.isTouristUae,
                    isTouristKsa: country === Country.KSA ? isTourist : user?.isTouristKsa,
                })
            } catch (error) {
                console.error(error)
            }
        },
        [country, updateTourist, user?.isTouristKsa, user?.isTouristUae],
    )

    const getDocuments = useCallback(
        (ignoreDeclined = true) => {
            //Identity
            const identityFront = findDocuments(documentsArray, UploadedDocument.id, Side.front, ignoreDeclined) || null
            const identityBack = findDocuments(documentsArray, UploadedDocument.id, Side.back, ignoreDeclined) || null
            //Drivers License
            const driversLicenseFront =
                findDocuments(documentsArray, UploadedDocument.driversLicense, Side.front, ignoreDeclined) || null
            const driversLicenseBack =
                findDocuments(documentsArray, UploadedDocument.driversLicense, Side.back, ignoreDeclined) || null
            const internationalDriversLicenseBack =
                findDocuments(
                    documentsArray,
                    UploadedDocument.internationalDriversLicense,
                    Side.back,
                    ignoreDeclined,
                ) || null
            const internationalDriversLicenseFront =
                findDocuments(
                    documentsArray,
                    UploadedDocument.internationalDriversLicense,
                    Side.front,
                    ignoreDeclined,
                ) || null
            //Passport
            const passportFront =
                findDocuments(documentsArray, UploadedDocument.passport, Side.front, ignoreDeclined) || null
            //Visa
            const visaFront = findDocuments(documentsArray, UploadedDocument.visa, Side.front, ignoreDeclined) || null

            return {
                identityFront,
                identityBack,
                driversLicenseFront,
                driversLicenseBack,
                internationalDriversLicenseBack,
                internationalDriversLicenseFront,
                passportFront,
                visaFront,
            }
        },
        [documentsArray],
    )

    useEffect(() => {
        const pendingBookings = bookings?.some((booking: Booking) => booking.status === StatusType.PENDING_APPROVAL)
        if (pendingBookings) setHasPendingBooking(pendingBookings)
    }, [bookings])

    const allRequiredDocumentsIsReady = useCallback(() => {
        const documentsList = getDocuments()
        setDocuments(documentsList)

        const {
            identityFront,
            identityBack,
            driversLicenseFront,
            driversLicenseBack,
            internationalDriversLicenseBack,
            internationalDriversLicenseFront,
            passportFront,
            visaFront,
        } = documentsList

        if ((country === Country.KSA && user?.isTouristKsa) || (country === Country.UAE && user?.isTouristUae)) {
            if (requiredDocuments?.TOURIST.ID_CARD?.isMandatory && (!identityFront || !identityBack)) return false
            if (
                requiredDocuments?.TOURIST.DRIVERS_LICENSE?.isMandatory &&
                (!driversLicenseFront || !driversLicenseBack)
            )
                return false
            if (
                requiredDocuments?.TOURIST.INTERNATIONAL_DRIVERS_LICENSE?.isMandatory &&
                (!internationalDriversLicenseBack || !internationalDriversLicenseFront)
            )
                return false
            if (requiredDocuments?.TOURIST.PASSPORT?.isMandatory && !passportFront) return false
            if (requiredDocuments?.TOURIST.VISA?.isMandatory && !visaFront) return false
        }
        if ((country === Country.KSA && !user?.isTouristKsa) || (country === Country.UAE && !user?.isTouristUae)) {
            if (requiredDocuments?.RESIDENT.ID_CARD?.isMandatory && (!identityFront || !identityBack)) return false
            if (
                requiredDocuments?.RESIDENT.DRIVERS_LICENSE?.isMandatory &&
                (!driversLicenseFront || !driversLicenseBack)
            )
                return false
            if (
                requiredDocuments?.RESIDENT.INTERNATIONAL_DRIVERS_LICENSE?.isMandatory &&
                (!internationalDriversLicenseBack || !internationalDriversLicenseFront)
            )
                return false
            if (requiredDocuments?.RESIDENT.PASSPORT?.isMandatory && !passportFront) return false
            if (requiredDocuments?.RESIDENT.VISA?.isMandatory && !visaFront) return false
        }

        return true
    }, [
        country,
        getDocuments,
        requiredDocuments?.RESIDENT.DRIVERS_LICENSE,
        requiredDocuments?.RESIDENT.ID_CARD,
        requiredDocuments?.RESIDENT.INTERNATIONAL_DRIVERS_LICENSE,
        requiredDocuments?.RESIDENT.PASSPORT,
        requiredDocuments?.RESIDENT.VISA,
        requiredDocuments?.TOURIST.DRIVERS_LICENSE,
        requiredDocuments?.TOURIST.ID_CARD,
        requiredDocuments?.TOURIST.INTERNATIONAL_DRIVERS_LICENSE,
        requiredDocuments?.TOURIST.PASSPORT,
        requiredDocuments?.TOURIST.VISA,
        user?.isTouristKsa,
        user?.isTouristUae,
    ])

    useEffect(() => {
        if (!verificationStatusLoading && documentsArray && requiredDocuments) {
            const verifiedStatus = allRequiredDocumentsIsReady()
            setIsUserVerified(verifiedStatus)
        }
    }, [allRequiredDocumentsIsReady, documentsArray, requiredDocuments, verificationStatusLoading])

    const verificationStatus = useMemo(() => {
        const allDocuments = getDocuments(false)

        const documentStatuses = [
            allDocuments?.internationalDriversLicenseFront?.status || allDocuments?.driversLicenseFront?.status,
            allDocuments?.internationalDriversLicenseBack?.status || allDocuments?.driversLicenseBack?.status,
            allDocuments?.identityBack?.status,
            allDocuments?.identityFront?.status,
            allDocuments?.passportFront?.status,
            allDocuments?.visaFront?.status,
        ]

        return documentStatuses.reduce((acc: VerificationStatus, status): VerificationStatus => {
            if (acc == VerificationStatus.approved && status == VerificationStatus.approved)
                return VerificationStatus.approved
            if (acc == VerificationStatus.declined || status == VerificationStatus.declined)
                return VerificationStatus.declined
            if (acc == VerificationStatus.resubmissionRequested || status == VerificationStatus.resubmissionRequested)
                return VerificationStatus.resubmissionRequested
            if (acc == VerificationStatus.pending || status == VerificationStatus.pending)
                return VerificationStatus.pending
            if (acc == VerificationStatus.awaitingSubmission || status == VerificationStatus.awaitingSubmission)
                return VerificationStatus.awaitingSubmission
            if (!status) return VerificationStatus.awaitingSubmission
            if (status == VerificationStatus.submitted) return VerificationStatus.submitted
            return acc
        }, documentStatuses[0] ?? VerificationStatus.awaitingSubmission)
    }, [getDocuments])

    return {
        isUserVerified,
        hasPendingBooking,
        documents,
        verificationStatus,
        bookings: bookings || [],
        isLoading: verificationStatusLoading,
        verificationStatusRefetch,
        saveIsTouristFlag,
        requiredDocuments,
        getDocuments,
    }
}
