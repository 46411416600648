export const DocumentsNavigationIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14 7.75C13.5858 7.75 13.25 8.08579 13.25 8.5C13.25 8.91421 13.5858 9.25 14 9.25H17.5C17.9142 9.25 18.25 8.91421 18.25 8.5C18.25 8.08579 17.9142 7.75 17.5 7.75H14Z"
                fill="#282D33"
            />
            <path
                d="M13.25 11.5C13.25 11.0858 13.5858 10.75 14 10.75H17.5C17.9142 10.75 18.25 11.0858 18.25 11.5C18.25 11.9142 17.9142 12.25 17.5 12.25H14C13.5858 12.25 13.25 11.9142 13.25 11.5Z"
                fill="#282D33"
            />
            <path
                d="M14 13.75C13.5858 13.75 13.25 14.0858 13.25 14.5C13.25 14.9142 13.5858 15.25 14 15.25H17.5C17.9142 15.25 18.25 14.9142 18.25 14.5C18.25 14.0858 17.9142 13.75 17.5 13.75H14Z"
                fill="#282D33"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.9333 12H8.0667C9.74246 12 11.156 13.2478 11.3638 14.9106C11.4361 15.4891 10.9851 16 10.4022 16H5.59783C5.01489 16 4.56386 15.4891 4.63617 14.9106C4.84402 13.2478 6.25754 12 7.9333 12ZM9.69356 14.5C9.3909 13.9018 8.77102 13.5 8.0667 13.5H7.9333C7.22898 13.5 6.6091 13.9018 6.30644 14.5H9.69356Z"
                fill="#282D33"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10 9C10 10.1046 9.10457 11 8 11C6.89543 11 6 10.1046 6 9C6 7.89543 6.89543 7 8 7C9.10457 7 10 7.89543 10 9ZM8.5 9C8.5 9.27614 8.27614 9.5 8 9.5C7.72386 9.5 7.5 9.27614 7.5 9C7.5 8.72386 7.72386 8.5 8 8.5C8.27614 8.5 8.5 8.72386 8.5 9Z"
                fill="#282D33"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2 7C2 5.34315 3.34315 4 5 4H19C20.6569 4 22 5.34315 22 7V17C22 18.6569 20.6569 20 19 20H5C3.34315 20 2 18.6569 2 17V7ZM5 5.5H19C19.8284 5.5 20.5 6.17157 20.5 7V17C20.5 17.8284 19.8284 18.5 19 18.5H5C4.17157 18.5 3.5 17.8284 3.5 17V7C3.5 6.17157 4.17157 5.5 5 5.5Z"
                fill="#282D33"
            />
        </svg>
    )
}
