import { useMutation } from 'react-query'
import { identityService } from '@service/identity'
import { ServerLanguageCode } from '@util/enums'

export const useUpdatePreferredLanguage = () => {
    return useMutation({
        mutationFn: async (preferredLanguage: ServerLanguageCode) => {
            return await identityService.updateUserPreferredLanguage(preferredLanguage)
        },
    })
}
