import { useAuth } from '@contexts/auth'
import { City, CityCode } from '@util/enums'
import { getCityCodeByCity } from '@util/localization'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export type CityItem = { value: City; text: string; enabled: boolean; locale: CityCode }
export type CitySelectorItem = { enabled: boolean; cities: CityItem[] }

const defaultCities = [City.abudhabi, City.dubai]

export const useCitySelector = (cities = defaultCities): { citySelector: CitySelectorItem } => {
    const { isCareem } = useAuth()
    const { t } = useTranslation()

    const getCityItem = useCallback(
        (acc: CityItem[], item: City) => {
            acc.push({
                value: item,
                locale: getCityCodeByCity(item),
                text: t(item),
                enabled: true,
            })
            return acc
        },
        [t],
    )

    const citySelector = useMemo(() => {
        if (!isCareem)
            return {
                enabled: true,
                cities: cities.reduce(getCityItem, [] as CityItem[]),
            }

        return {
            enabled: false,
            cities: [],
        }
    }, [cities, getCityItem, isCareem])

    return { citySelector }
}
