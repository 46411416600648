import styled from 'styled-components'

export const MobileDropDown = styled.div`
    z-index: 50;
    top: var(--navMobileHeight);
    position: absolute;
    background: white;
    width: 100%;
    border-radius: 0 0 16px 16px;
`

export const MobileDropDownContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: var(--padding) var(--padding) 6px;
`

export const MobileDropDownItemContainer = styled.div`
    padding: 0 0 var(--padding) 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const MobileDropDownMenuText = styled.p<{ isOpen?: boolean }>`
    font-weight: var(--weight-semiBold);
    font-size: var(--size-14);
    margin: 0;
    color: ${({ isOpen }) => (isOpen ? `var(--primaryColor)` : 'rgba(0, 0, 0, 0.8)')};
`

export const MobileDropDownComposedText = styled.div<{ isOpen?: boolean }>`
    font-weight: var(--weight-semiBold);
    font-size: var(--size-14);
    margin: 0;
    color: ${({ isOpen }) => (isOpen ? `var(--primaryColor)` : 'rgba(0, 0, 0, 0.8)')};
    display: flex;
    justify-content: space-between;
    width: 100%;
`

export const MobileDropdownItem = styled.div<{ isDisabled?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: start;
    text-decoration: none;
    width: 100%;

    p,
    svg {
        color: ${({ isDisabled }) => isDisabled && '#c7c7c7'};
    }
`

export const MobileDropDownIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    margin-inline-end: 1rem;
    margin-inline-start: 1rem;
`

export const Description = styled.p`
    font-weight: 500;
    text-align: start;
    font-size: 14px;
    margin: 0;
    & > a {
        color: var(--primaryColor);
    }
`

export const NavIconAndLink = styled.div<{ isDisabled?: boolean }>`
    display: flex;
    align-items: center;
    cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};

    & > p {
        margin-inline-start: 10px;
    }
`
