import React, { FC } from 'react'
import styled from 'styled-components'
import { CarImage } from '@components/modules/Car/CarImage'
import { ListingsResponseData } from '@service/booking.types'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { formatPrice } from '@util/config'
import { convertToTranslationKey } from '@util/functions'
import { TranslationPrefix } from '@util/enums'
import { device } from '@util/responsive'
import { CarTag } from '@global/CarTag'
import { ProductType } from '@service/configuration.types'
import { useGlobalState } from '@contexts/global'
import { ServiceFee, TotalFee } from './Card'
import { useCarUtils } from '@hooks/useCarUtils'

type CarCardProps = {
    car: ListingsResponseData
    preload?: boolean
}

export const DesktopCarCard: FC<CarCardProps> = ({ car, preload }) => {
    const { t } = useTranslation()
    const { push, query } = useRouter()
    const { bookingState } = useGlobalState()
    const { nameModelGeneration } = useCarUtils(car)
    const isDaily = bookingState.productType === ProductType.DAILY

    const { currency } = car.pricingDetails
    const currencyTranslated = t(convertToTranslationKey(currency, TranslationPrefix.currency), currency)

    if (!car.pricingDetails) {
        return null
    }

    return (
        <CarCardContainer data-testid={`car-card_${car.id}`} onClick={() => push(`/${query?.city}/cars/${car.id}`)}>
            <CarImgWrapper>
                <CarImage src={car.coverPhoto} preload={preload} />
                <CarTagWrapper>
                    {car.tags.map((carTag) => (
                        <CarTag
                            key={carTag.id}
                            backgroundColor={`#${carTag.backgroundHexCode}`}
                            color={carTag.isLabelDark ? '#000000' : '#ffffff'}
                            data-testid={`rental_car_tag_${carTag.id}`}
                        >
                            {t(carTag.i18nKey)}
                        </CarTag>
                    ))}
                </CarTagWrapper>
            </CarImgWrapper>
            <CarDataWrapper>
                <CarTitleWrapper>
                    <CarHeading data-testid={`rental_car_name`} color="rgba(0, 0, 0, 1)">
                        {nameModelGeneration}
                    </CarHeading>
                </CarTitleWrapper>
                <CarPriceWrapper>
                    <CarHeading
                        className="priceText"
                        color={'var(--primaryColor)'}
                        fontWeigth={'var(--weight-extraBold)'}
                    >
                        <b data-testid="dailyPrice">
                            {t('carCardPrice', '{{ amount }} {{currency}}', {
                                amount: formatPrice(car.pricingDetails.periodPrice),
                                currency: currencyTranslated,
                            })}
                        </b>{' '}
                        {isDaily ? t('carCardPerDay', ' / day') : t('carCardPerMonth', ' / month')}
                    </CarHeading>
                    <TotalFee
                        currency={currencyTranslated}
                        totalRentalFee={car.pricingDetails.totalRentalFee}
                        isShown={bookingState.productType === ProductType.DAILY}
                    />
                    {car.productType === ProductType.MONTHLY && (
                        <ServiceFee
                            currency={currencyTranslated}
                            serviceFeeAmount={car.pricingDetails.serviceFee}
                            justify="flex-end"
                        />
                    )}
                </CarPriceWrapper>
            </CarDataWrapper>
            <CarFeaturesBg>
                <CarFeaturesWrapper>
                    <CarProperty data-testid={`rental_car_property_transmission`}>
                        {t(`carProperty.${car.gearboxType.toLowerCase()}`, '')}
                    </CarProperty>
                    <CarProperty data-testid={`rental_car_property_type`}>
                        {t(`carProperty.${car.carType.toLowerCase()}`, '')}
                    </CarProperty>
                    <CarProperty data-testid={`rental_car_property_passenger-count`}>
                        {t('carDetailsFeatureBadgePassengerCount', '{{ passengerCount }} person', {
                            passengerCount: car.seats,
                        })}
                    </CarProperty>
                    {car.engineSize && (
                        <CarProperty data-testid={`rental_car_property_engine-type`}>{car.engineSize}</CarProperty>
                    )}
                </CarFeaturesWrapper>
            </CarFeaturesBg>
        </CarCardContainer>
    )
}

const CarCardContainer = styled.div`
    background: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.04), 0 2px 20px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 0 16px 0;
    border-radius: 12px;
    width: 100%;
    position: relative;
    z-index: 10;
    cursor: pointer;
`

const CarImgWrapper = styled.div`
    border-radius: 12px 12px 0 0;
    width: 100%;
    background: white;
    padding: 0px;
    margin: 0 0 -5px;
    overflow: hidden;
`

const CarTagWrapper = styled.div`
    display: inline-flex;
    gap: 5px;
    position: absolute;
    top: 16px;
    right: 16px;
    white-space: nowrap;
    flex-wrap: wrap;
`

const CarTitleWrapper = styled.div`
    max-width: 50%;
    display: flex;
    height: 50px;
    flex: 1;
`

const CarDataWrapper = styled.div`
    background: white;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
    gap: 10px;
`

const CarPriceWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 50px;
`

const CarHeading = styled.h3<{ color?: string; fontWeigth?: string }>`
    font-size: var(--size-20);
    @media ${device.laptop} {
        font-size: calc(100vw / 1300 * 20);
    }
    @media ${device.container} {
        font-size: var(--size-20);
    }
    color: ${(props) => props.color || ''};
    font-weight: ${(props) => props.fontWeigth || ''};
    margin: 0;

    & > span {
        font-size: var(--size-14);
        font-weight: var(--weight-regular);
    }
`

const CarFeaturesBg = styled.div`
    background: white;
`
const CarFeaturesWrapper = styled.div`
    margin: 0 20px;
    padding: 1rem 0 0 0;
    display: flex;
    column-gap: 12px;
    flex-wrap: nowrap;
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        /* Chrome, Safari */
        display: none;
    }
`

const CarProperty = styled.div`
    white-space: nowrap;
    background: #f1f1f1;
    color: rgba(0, 0, 0, 0.6);
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6px 16px;
    font-weight: 500;
    font-size: 12px;
`
