export const HomenavigationIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.0304 14.9698C9.73748 14.6769 9.26261 14.6769 8.96971 14.9698C8.67682 15.2627 8.67682 15.7375 8.96971 16.0304L9.34839 16.4091C10.8129 17.8736 13.1872 17.8736 14.6517 16.4091L15.0304 16.0304C15.3233 15.7375 15.3233 15.2627 15.0304 14.9698C14.7375 14.6769 14.2626 14.6769 13.9697 14.9698L13.591 15.3484C12.7124 16.2271 11.2877 16.2271 10.4091 15.3484L10.0304 14.9698Z"
                fill="#282D33"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.0671 3.87463C12.4381 3.39075 11.5622 3.39075 10.9331 3.87462L5.0464 8.40284L3.05005 9.90009C2.71868 10.1486 2.65152 10.6187 2.90004 10.9501C3.14857 11.2815 3.61867 11.3486 3.95004 11.1001L4.75007 10.5001L4.7501 19.5001C4.75011 20.4666 5.53361 21.2501 6.5001 21.2501H17.5001C18.4666 21.2501 19.2501 20.4666 19.2501 19.5001V10.5001L20.05 11.1001C20.3814 11.3486 20.8515 11.2815 21.1 10.9501C21.3486 10.6187 21.2814 10.1486 20.9501 9.90009L18.9538 8.40283L13.0671 3.87463ZM6.2501 19.5001L6.25007 9.36939L11.8477 5.06356C11.9375 4.99444 12.0627 4.99444 12.1525 5.06356L17.7501 9.36939V19.5001C17.7501 19.6382 17.6382 19.7501 17.5001 19.7501H6.5001C6.36203 19.7501 6.25011 19.6382 6.2501 19.5001Z"
                fill="#282D33"
            />
        </svg>
    )
}
