export const SupportNavigationIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 2.75C7.99594 2.75 4.75 5.99594 4.75 10V10.4184C4.15876 10.6993 3.75 11.3019 3.75 12V15C3.75 15.9665 4.5335 16.75 5.5 16.75H6.5C7.4665 16.75 8.25 15.9665 8.25 15V12C8.25 11.0335 7.4665 10.25 6.5 10.25H6.25V10C6.25 6.82436 8.82436 4.25 12 4.25C15.1756 4.25 17.75 6.82436 17.75 10V10.25H17.5C16.5335 10.25 15.75 11.0335 15.75 12V15C15.75 15.9665 16.5335 16.75 17.5 16.75H17.75V17C17.75 18.1648 16.8649 19.1229 15.7306 19.2383C15.6043 18.396 14.8776 17.75 14 17.75H12C11.0335 17.75 10.25 18.5335 10.25 19.5V20C10.25 20.9665 11.0335 21.75 12 21.75H14C14.6984 21.75 15.3013 21.3408 15.582 20.7491C17.6152 20.7055 19.25 19.0437 19.25 17V16.5816C19.8412 16.3007 20.25 15.6981 20.25 15V12C20.25 11.3019 19.8412 10.6993 19.25 10.4184V10C19.25 5.99594 16.0041 2.75 12 2.75ZM18.5 15.25C18.6381 15.25 18.75 15.1381 18.75 15V12C18.75 11.8619 18.6381 11.75 18.5 11.75H17.5C17.3619 11.75 17.25 11.8619 17.25 12V15C17.25 15.1381 17.3619 15.25 17.5 15.25H18.5ZM5.25 12C5.25 11.8619 5.36193 11.75 5.5 11.75H6.5C6.63807 11.75 6.75 11.8619 6.75 12V15C6.75 15.1381 6.63807 15.25 6.5 15.25H5.5C5.36193 15.25 5.25 15.1381 5.25 15V12ZM14.25 19.5V20C14.25 20.1381 14.1381 20.25 14 20.25H12C11.8619 20.25 11.75 20.1381 11.75 20V19.5C11.75 19.3619 11.8619 19.25 12 19.25H14C14.1381 19.25 14.25 19.3619 14.25 19.5Z"
                fill="#282D33"
            />
        </svg>
    )
}
