export const MiniArrowRight: React.FC<{ color?: string }> = ({ color }) => (
    <svg
        className="iconSvgArrow"
        width="8"
        height="14"
        viewBox="0 0 8 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0.91996 13.0067C1.24663 13.3334 1.77329 13.3334 2.09996 13.0067L7.63996 7.46671C7.89996 7.20671 7.89996 6.78671 7.63996 6.52671L2.09996 0.986706C1.7733 0.66004 1.24663 0.66004 0.919962 0.986706C0.593295 1.31337 0.593295 1.84004 0.919961 2.16671L5.74663 7.00004L0.913295 11.8334C0.593295 12.1534 0.593294 12.6867 0.91996 13.0067Z"
            fill={color ? color : '#000000'}
        />
    </svg>
)
