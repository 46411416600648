import { axios } from '@util/axios'
import { config } from '@util/config'
import { getMockProxy } from '@util/mock'
import { ServiceResponse } from 'src/types/global'
import { mockBookingService } from './booking.mock'
import {
    ListingByIdResponse,
    ListingFilter,
    ListingsResponse,
    BookingResponse,
    Booking,
    CustumerBookingDocument,
    BookingHandbackUpdateBody,
    BookingUpdateResponse,
    DateCalculation,
    DateCalculationBody,
    MyBookingsFilter,
    Address,
    CreateBookingWithPaymentMethod,
    CancellationReasonParam,
    MonthlyBookingHandbackUpdateBody,
    CityCodeProductTypeFilter,
    MakeDto,
    ModelDto,
    CarFeature,
    ModelsByMakesFilter,
    PriceCalculationBody,
    PriceCalculationResponse,
} from './booking.types'
import { AxiosResponse } from 'axios'
import { MonthlyHandbackDateUpdateResponse, UserBillingAddressResponse, UserEditBillingAddress } from './payment.types'

const BOOKING_API_URL = `${config.BASE_URL}/booking`

export type BookingService = {
    getUserBillingDetails: () => Promise<AxiosResponse<UserBillingAddressResponse>>
    createOrUpdateUserBillingAddress: (
        userBillingAddress: UserEditBillingAddress,
    ) => Promise<AxiosResponse<UserBillingAddressResponse>>
    getListings: (filterParams: ListingFilter) => Promise<AxiosResponse<ListingsResponse>>
    getListingById: (listingsId: number) => ServiceResponse<ListingByIdResponse>
    createBooking: (bookingParams: CreateBookingWithPaymentMethod) => ServiceResponse<BookingResponse>
    finalizeBooking: (bookingId: string | number) => ServiceResponse<BookingResponse>
    updateBookingHandbackAddress: (bookingId: string | number, handbackAddressData: Address) => ServiceResponse<any>
    cancelBooking: (
        bookingId: number,
        cancellationReasonParam: CancellationReasonParam,
    ) => ServiceResponse<BookingResponse>
    finalBookingHandbackDateChange: (bookingId: string | number) => ServiceResponse<BookingResponse>
    updateBookingHandbackDate: (
        bookingId: string | number,
        handbackData: BookingHandbackUpdateBody,
    ) => ServiceResponse<BookingUpdateResponse>
    updateMonthlyBookingHandbackDate: (
        bookingId: string | number,
        handbackData: MonthlyBookingHandbackUpdateBody,
    ) => ServiceResponse<MonthlyHandbackDateUpdateResponse>
    getCustomerBookingById: (bookingId: string | number) => ServiceResponse<Booking>
    getBookings: (filter?: MyBookingsFilter) => ServiceResponse<Booking[]>
    getListingLocationById: (locationId: string | number) => ServiceResponse<any>
    getBookingDocuments: (bookingId: string | number) => Promise<AxiosResponse<CustumerBookingDocument[]>>
    downloadBookingDocument: (bookingId: string | number, documentKey: string) => ServiceResponse<any>
    dateCalculationForBooking: (bookingId: string | number) => ServiceResponse<DateCalculation>
    dateCalculationForListing: (
        listingId: string | number,
        body?: DateCalculationBody,
    ) => ServiceResponse<DateCalculation>
    getCarFeatures: (filter: CityCodeProductTypeFilter) => ServiceResponse<CarFeature[]>
    getMakes: (filter: CityCodeProductTypeFilter) => ServiceResponse<MakeDto[]>
    getModelsForMakes: (filter: ModelsByMakesFilter) => ServiceResponse<ModelDto[]>
    getCityForBooking: (bookingId: string | number) => ServiceResponse<string>
    priceCalculation: (listingId: number, body: PriceCalculationBody) => ServiceResponse<PriceCalculationResponse>
}

const realBookingService: Partial<BookingService> = {
    getUserBillingDetails: async () => {
        return await axios.get(`${BOOKING_API_URL}/me/billing-details`)
    },
    createOrUpdateUserBillingAddress: async (userBillingAddress) => {
        return await axios.put(`${BOOKING_API_URL}/me/billing-details`, {
            ...userBillingAddress,
        })
    },
    getListings: async (filterParams) => {
        return await axios.get(`${BOOKING_API_URL}/listings`, {
            params: filterParams,
        })
    },
    getListingById: async (listingsId) => {
        return await axios.get(`${BOOKING_API_URL}/listings/${listingsId}`)
    },
    getBookings: async (filter?: MyBookingsFilter) => {
        return await axios.get<MyBookingsFilter, ServiceResponse<Booking[]>>(`${BOOKING_API_URL}/me/bookings`, {
            params: filter,
        })
    },
    createBooking: async (bookingParams) => {
        return await axios.post(`${BOOKING_API_URL}/me/bookings`, bookingParams)
    },
    finalizeBooking: async (bookingId: string | number) => {
        return await axios.patch(`${BOOKING_API_URL}/me/bookings/${bookingId}/finalize`, {})
    },
    cancelBooking: async (bookingId, cancelParams) => {
        return await axios.patch(`${BOOKING_API_URL}/me/bookings/${bookingId}/cancel`, cancelParams)
    },
    finalBookingHandbackDateChange: async (bookingId) => {
        return await axios.patch(`${BOOKING_API_URL}/me/bookings/${bookingId}/handback-date/final`, {})
    },
    updateBookingHandbackDate: async (bookingId: string | number, handbackData: BookingHandbackUpdateBody) => {
        return await axios.patch(`${BOOKING_API_URL}/me/bookings/${bookingId}/handback-date`, handbackData)
    },
    updateMonthlyBookingHandbackDate: async (bookingId, handbackData: MonthlyBookingHandbackUpdateBody) => {
        return await axios.patch(`${BOOKING_API_URL}/me/bookings/${bookingId}/monthly/handback-date`, handbackData)
    },
    updateBookingHandbackAddress: async (bookingId: string | number, handbackAddressData: Address) => {
        return await axios.patch(
            `${BOOKING_API_URL}/me/bookings/${bookingId}/d2d-handback-address`,
            handbackAddressData,
        )
    },
    getCustomerBookingById: async (bookingId: string | number) => {
        return await axios.get(`${BOOKING_API_URL}/me/bookings/${bookingId}`)
    },
    getListingLocationById: async (locationId: string | number) => {
        return await axios.get(`${BOOKING_API_URL}/locations/${locationId}`)
    },
    getBookingDocuments: async (bookingId: string | number) => {
        return await axios.get(`${BOOKING_API_URL}/me/bookings/${bookingId}/documents`)
    },
    downloadBookingDocument: async (bookingId: string | number, documentKey: string) => {
        return await axios.get(`${BOOKING_API_URL}/me/bookings/${bookingId}/documents/${documentKey}`, {
            responseType: 'blob',
        })
    },
    dateCalculationForBooking: async (bookingId: string | number) => {
        return await axios.post(`${BOOKING_API_URL}/me/bookings/${bookingId}/date-calculation`, {})
    },
    dateCalculationForListing: async (listingId: string | number, body?: DateCalculationBody) => {
        return await axios.post(`${BOOKING_API_URL}/listings/${listingId}/date-calculation`, body || {})
    },
    getCarFeatures: async (filter: CityCodeProductTypeFilter) => {
        return await axios.get(`${BOOKING_API_URL}/car-features/available`, { params: filter })
    },
    getMakes: async (filter: CityCodeProductTypeFilter) => {
        return await axios.get(`${BOOKING_API_URL}/makes/available`, { params: filter })
    },
    getModelsForMakes: async (filter: ModelsByMakesFilter) => {
        return await axios.get(`${BOOKING_API_URL}/models/available`, { params: filter })
    },
    getCityForBooking: async (bookingId: string | number) => {
        return await axios.get(`${BOOKING_API_URL}/unauthorized/city/${bookingId}`)
    },
    priceCalculation: async (
        listingId: number,
        body: PriceCalculationBody,
    ): ServiceResponse<PriceCalculationResponse> => {
        return axios.post(`${BOOKING_API_URL}/listings/${listingId}/price-calculation`, body)
    },
}

export const bookingService: BookingService = getMockProxy(realBookingService, mockBookingService)
