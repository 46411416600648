import { axiosify } from '@util/mock'
import { BookingService } from './booking'
import {
    CarType,
    GearBoxType,
    ListingByIdResponse,
    ListingsResponse,
    BookingResponse,
    Booking,
    StatusType,
    CustumerBookingDocument,
    BookingUpdateResponse,
    DateCalculation,
    MyBookingsFilter,
} from './booking.types'
import { Currency, ProductType } from './configuration.types'
import { AxiosResponse } from 'axios'
import { MonthlyHandbackDateUpdateResponse } from './payment.types'
import { CityCode } from '@util/enums'

const listingsData: ListingsResponse = {
    paginationInfo: {
        currentPage: 0,
        pageSize: 0,
        totalElements: 0,
        totalPages: 0,
    },
    data: [
        {
            id: 0,
            pricingDetails: {
                periodPrice: 10000,
                totalRentalFee: 220000,
                currency: 'HUF',
                serviceFee: 5000,
            },
            coverPhoto: '/rental/MockCar.png',
            make: 'BMW',

            model: 'i8',
            generation: '2022',
            carType: CarType.SUV,
            gearboxType: GearBoxType.AUTOMATIC,
            seats: 5,
            carFeatures: [
                { id: 1, i18nKey: 'parkingSensors' },
                { id: 2, i18nKey: 'airConditioning' },
                { id: 3, i18nKey: 'heatedSeats' },
            ],
            engineSize: '1.6L',
            makeI18nKey: 'carModel.bmw',
            modelI18nKey: 'carMake.i8',
            tags: [],
            productType: ProductType.DAILY,
        },
        {
            id: 2,
            pricingDetails: {
                periodPrice: 10000,
                totalRentalFee: 220000,
                currency: 'HUF',
                serviceFee: 5000,
            },
            coverPhoto: '/rental/MockCar.png',
            make: 'BMW',
            model: 'i8',
            generation: '2022',
            carType: CarType.SUV,
            gearboxType: GearBoxType.AUTOMATIC,
            seats: 5,
            carFeatures: [{ id: 1, i18nKey: 'parkingSensor' }],
            engineSize: '1.6L',
            makeI18nKey: 'carModel.bmw',
            modelI18nKey: 'carMake.i8',
            tags: [],
            productType: ProductType.DAILY,
        },
    ],
}

const listingByIdData: ListingByIdResponse = {
    coverPhoto: '',
    tags: [],
    id: 0,
    pricingDetails: {
        periodPrice: 1000,
        totalRentalFee: 221000,
        extraInsuranceFee: 4210,
        allowedKmsPerDay: 175,
        extraMileageFee: 340,
        currency: 'HUF',
    },
    photos: [
        {
            id: 0,
            imageKey: 'string',
            cover: true,
            position: 0,
        },
    ],
    carFeatures: [
        {
            i18nKey: 'string',
            id: 0,
        },
    ],
    colors: [
        {
            id: 0,
            i18nKey: 'string',
            hexCode: 'stringS',
        },
    ],
    make: 'BMW',
    model: 'i8',
    generation: '2022',
    carType: CarType.COUPE,
    gearboxType: GearBoxType.AUTOMATIC,
    seats: 5,
    engineSize: '1.6L',
    location: null,
    d2dAvailable: true,
    supplierLocations: [],
    makeI18nKey: 'carModel.bmw',
    modelI18nKey: 'carMake.i8',
    productType: ProductType.DAILY,
    packageType: 'package',
}

const bookingResponse: BookingResponse = {
    bookingId: 0,
    paymentRefId: 'string',
}

const dateCalculationForBookingResponse: DateCalculation = {
    firstAvailableHandbackDate: '2023-01-15',
    unavailableDates: ['2023-01-20', '2023-01-21'],
}

const bookingUpdateResponse: BookingUpdateResponse = {
    bookingId: 0,
    paymentRefId: 'string',
}

const monthlybookingUpdateResponse: MonthlyHandbackDateUpdateResponse = {
    originalBooking: {
        bookingId: 0,
        listingDescription: 'string',
        cityCode: CityCode.DXB,
        currency: Currency.AED,
        startDate: '2023-05-03',
        endDate: '2023-05-03',
        rentalPeriodDays: 0,
        priceDetails: {
            externalListingId: 0,
            vatPercentage: 0,
            depositMonthsToCharge: 0,
            dailyPrice: 0,
            deliveryFee: 0,
            monthlyBasePrice: 0,
            monthlyPrice: 0,
            totalDeposit: 0,
            firstCharge: 0,
            totalRentalFee: 0,
            totalRentalFeeAfterDiscount: 0,
            totalExtraInsuranceFee: 0,
            secondaryDriverFee: 0,
            totalSecondaryDriverFee: 0,
            discountAmount: 0,
            allowedKmsPerYear: 0,
            surchargeExtraKmsPerUnit: 0,
            couponDetails: {
                code: 'string',
                valid: true,
                errors: [
                    {
                        type: 'NOT_FOUND',
                        parameter: {},
                    },
                ],
                description: 'string',
                discountAmount: 0,
                discountRate: 0,
                discountAmountLimit: 0,
                minRentalPeriod: 0,
                maxRentalPeriod: 0,
                minRentalFee: 0,
                minMonthlyRentalFee: 0,
            },
            periodPrice: 0,
            serviceFee: 50,
        },
        balance: 0,
        freeCancellationEndsAt: '2023-05-03T10:11:28.783Z',
        earliestPossibleEndDate: '2023-05-03',
    },
    updatedBooking: {
        bookingId: 0,
        listingDescription: 'string',
        cityCode: 'string',
        currency: 'string',
        startDate: '2023-05-03',
        endDate: '2023-05-03',
        rentalPeriodDays: 0,
        priceDetails: {
            externalListingId: 0,
            vatPercentage: 0,
            depositMonthsToCharge: 0,
            monthlyBasePrice: 0,
            monthlyPrice: 0,
            totalDeposit: 0,
            dailyPrice: 0,
            deliveryFee: 0,
            firstCharge: 0,
            totalRentalFee: 0,
            totalRentalFeeAfterDiscount: 0,
            discountAmount: 0,
            allowedKmsPerYear: 0,
            surchargeExtraKmsPerUnit: 0,
            totalExtraInsuranceFee: 0,
            secondaryDriverFee: 0,
            totalSecondaryDriverFee: 0,
            couponDetails: {
                code: 'string',
                valid: true,
                errors: [
                    {
                        type: 'NOT_FOUND',
                        parameter: {},
                    },
                ],
                description: 'string',
                discountAmount: 0,
                discountRate: 0,
                discountAmountLimit: 0,
                minRentalPeriod: 0,
                maxRentalPeriod: 0,
                minRentalFee: 0,
                minMonthlyRentalFee: 0,
            },
            periodPrice: 0,
            serviceFee: 50,
        },
        balance: 0,
        freeCancellationEndsAt: '2023-05-03T10:11:28.783Z',
        earliestPossibleEndDate: '2023-05-03',
    },
}

const bookingCustomerByIdResponse: Booking = {
    carDetails: {
        make: 'BMW',
        model: 'i8',
        makeI18nKey: 'carModel.bmw',
        modelI18nKey: 'carMake.i8',
    },
    selfPickupHandbackAddress: {
        id: 0,
        addressLine1: 'string',
        addressLine2: 'string',
        city: 'string',
        region: 'string',
        zipCode: 'string',
        country: 'string',
        latitude: '1.111',
        longitude: '1.111',
    },
    selfPickupHandoverAddress: {
        id: 0,
        addressLine1: 'string',
        addressLine2: 'string',
        city: 'string',
        region: 'string',
        zipCode: 'string',
        country: 'string',
        latitude: '1.111',
        longitude: '1.111',
    },
    bookingId: 0,

    listingId: 0,
    customerId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    supplierId: 11,
    status: StatusType.PENDING_PAYMENT,
    handoverTime: '2022-03-14T10:03:50.792Z',
    handbackTime: '2022-03-14T10:03:50.792Z',
    selfPickupHandbackLocationId: null,
    selfPickupHandoverLocationId: null,
    includeSecondaryDriver: false,
    timezone: 'string',
    d2dHandoverAddress: {
        id: 0,
        addressLine1: 'string',
        addressLine2: 'string',
        city: 'string',
        region: 'string',
        zipCode: 'string',
        country: 'string',
        latitude: '1.111',
        longitude: '1.111',
    },
    d2dHandbackAddress: {
        id: 0,
        addressLine1: 'string',
        addressLine2: 'string',
        city: 'string',
        region: 'string',
        zipCode: 'string',
        country: 'string',
        latitude: '1.111',
        longitude: '1.111',
    },
    productType: ProductType.DAILY,
    updatePaymentMethod: false,
    paymentDetails: {
        lastFourDigits: '6097',
        description: 'Visa ****-6097',
        cardProvider: 'Visa',
    },
}

const updateBillingDetailsData = {
    externalCustomerId: '00ffd178-c149-43d0-8b28-05f938013c1a',
    careemId: null,
    billingName: 'Test User',
    vatId: null,
    addressLine1: 'Dubai street 123',
    addressLine2: 'Third floor',
    city: 'Riyadh',
    region: 'MegRiyadh',
    zipCode: '11111',
    country: 'KSA',
    createdBy: '00ffd178-c149-43d0-8b28-05f938013c1a',
    createdAt: '2024-05-13T10:13:52.608043Z',
    updatedBy: '00ffd178-c149-43d0-8b28-05f938013c1a',
    updatedAt: '2024-05-13T10:13:52.608043Z',
}

const bookingDocumentsResponse: CustumerBookingDocument[] = [
    {
        bookingId: 0,
        documentKey: 'string',
        comment: 'string',
        createdBy: 'string',
        createdAt: 'string',
    },
]
export const mockBookingService: BookingService = {
    createOrUpdateUserBillingAddress: async () => {
        return (await axiosify(updateBillingDetailsData)) as any
    },
    getListings: async () => {
        return (await axiosify(listingsData)) as any
    },
    getListingById: async () => {
        return await axiosify(listingByIdData)
    },
    createBooking: async () => {
        return await axiosify(bookingResponse)
    },
    finalizeBooking: async () => {
        return await axiosify(bookingResponse)
    },
    cancelBooking: async () => {
        return await axiosify(bookingResponse)
    },
    updateBookingHandbackDate: async () => {
        return await axiosify(bookingUpdateResponse)
    },
    updateMonthlyBookingHandbackDate: async () => {
        return await axiosify(monthlybookingUpdateResponse)
    },
    updateBookingHandbackAddress: async () => {
        return await axiosify('string')
    },
    getCustomerBookingById: async () => {
        return await axiosify(bookingCustomerByIdResponse)
    },
    getBookings: async (_filter?: MyBookingsFilter) => {
        return await axiosify([])
    },
    getListingLocationById: async () => {
        return await axiosify(bookingResponse)
    },
    getBookingDocuments: async (): Promise<AxiosResponse<CustumerBookingDocument[]>> => {
        return await axiosify(bookingDocumentsResponse)
    },
    downloadBookingDocument: async () => {
        return await axiosify('string')
    },
    finalBookingHandbackDateChange: async () => {
        return await axiosify(bookingResponse)
    },
    dateCalculationForBooking: async () => {
        return await axiosify(dateCalculationForBookingResponse)
    },
    dateCalculationForListing: async () => {
        return await axiosify(dateCalculationForBookingResponse)
    },
    getCarFeatures: async () => {
        return await axiosify([])
    },
    getMakes: async () => {
        return await axiosify([])
    },
    getModelsForMakes: async () => {
        return await axiosify([])
    },
    getCityForBooking: async () => {
        return await axiosify('string')
    },
    getUserBillingDetails: async () => {
        return (await axiosify([])) as any
    },
    priceCalculation: async () => {
        return (await axiosify([])) as any
    },
}
