export const ProfileNavigationIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.0001 3C14.3724 3 16.0001 4.67482 16.0001 7C16.0001 9.32518 14.3724 12 12.0001 12C9.62771 12 8.00008 9.32518 8.00008 7C8.00008 4.67482 9.62771 3 12.0001 3ZM12.0001 4.5C12.8223 4.5 13.4272 4.78353 13.8246 5.18428C14.2239 5.58693 14.5001 6.19523 14.5001 7C14.5001 7.8418 14.1959 8.78143 13.6801 9.48495C13.1685 10.1826 12.5734 10.5 12.0001 10.5C11.4267 10.5 10.8317 10.1826 10.3201 9.48495C9.80423 8.78143 9.50008 7.8418 9.50008 7C9.50008 6.19523 9.77622 5.58692 10.1755 5.18428C10.5729 4.78353 11.1779 4.5 12.0001 4.5Z"
                fill="#282D33"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18.3385 20.0985C19.8829 19.6351 20.2819 17.6255 19.0228 16.6182L18.247 15.9976C14.5948 13.0758 9.40531 13.0758 5.75312 15.9976L4.97733 16.6182C3.71821 17.6255 4.11723 19.6351 5.66169 20.0985C9.78534 21.3356 14.2148 21.3356 18.3385 20.0985ZM17.31 17.1689L18.0858 17.7895C18.3898 18.0327 18.3067 18.542 17.9074 18.6617C14.0649 19.8145 9.93521 19.8145 6.09271 18.6617C5.69342 18.542 5.61032 18.0327 5.91437 17.7895L6.69016 17.1689C9.79452 14.6854 14.2056 14.6854 17.31 17.1689Z"
                fill="#282D33"
            />
        </svg>
    )
}
