import { useAuth } from '@contexts/auth'
import { bookingService } from '@service/booking'
import { useCallback, useMemo } from 'react'
import { useQuery } from 'react-query'
import { Booking, ListingByIdResponse, MyBookingsFilter, StatusType } from '@service/booking.types'
import { BookingStatus } from '@util/enums'

const DEFAULT_FILTER: MyBookingsFilter = {
    statuses: [StatusType.ACTIVE, StatusType.APPROVED, StatusType.PENDING_APPROVAL],
    sortBy: 'UPDATED_AT',
    sortDirection: 'DESC',
}

export const getMyBookingsKey = (filter?: MyBookingsFilter) => ['myBookings', filter]
export const getMyBookings = (filter?: MyBookingsFilter): Promise<Booking[] | undefined> =>
    bookingService.getBookings(filter).then((res) => res.data)

export const useMyBookings = (filter: MyBookingsFilter = DEFAULT_FILTER) => {
    const { user } = useAuth()
    const isLoggedIn = !!user
    const queryKey = useMemo(() => getMyBookingsKey(filter), [filter])
    return useQuery(queryKey, () => getMyBookings(filter), { initialData: [], enabled: isLoggedIn })
}

const ACTIVE_STATUSES = [
    StatusType.PENDING_PAYMENT,
    StatusType.ACTIVE,
    StatusType.PENDING_APPROVAL,
    StatusType.APPROVED,
]
const ACTIVE_STATUSES_FILTER = { statuses: ACTIVE_STATUSES }
export const useActiveBookings = () => {
    const { data: bookings = [] } = useMyBookings(ACTIVE_STATUSES_FILTER)
    return bookings
}

export const LISTING_BY_ID_KEY = (id?: number) => ['listingById', id ?? -1]
export const listingById = (id?: number): Promise<ListingByIdResponse | null> => {
    if (!id) {
        return Promise.resolve(null)
    }
    return bookingService.getListingById(id).then((res) => res.data || null)
}

export const useListingById = (id?: number) => {
    return useQuery(LISTING_BY_ID_KEY(id), () => listingById(id), { initialData: null, enabled: !!id })
}

export enum BookingTab {
    active = 'ACTIVE',
    upcoming = 'UPCOMING',
    past = 'PAST',
}

export const useMyRentals = () => {
    const { data: rentals } = useMyBookings({
        statuses: [
            StatusType.ACTIVE,
            StatusType.APPROVED,
            StatusType.PENDING_APPROVAL,
            StatusType.CANCELLED,
            StatusType.COMPLETED,
        ],
        sortBy: 'UPDATED_AT',
        sortDirection: 'DESC',
    })
    const checkRentalStatus = useCallback((rental: any) => {
        const statusIsActiveOnDaily = (rental: Booking, startDate: Date, endDate: Date | null, now: Date) =>
            (rental.status === StatusType.APPROVED && startDate <= now) ||
            (rental.status === StatusType.ACTIVE && endDate && endDate > now)

        const statusIsActiveOnMonthly = (rental: Booking, startDate: Date, endDate: Date | null, now: Date) =>
            statusIsActiveOnDaily(rental, startDate, endDate, now) || (rental.status === StatusType.ACTIVE && !endDate)

        const isPastOnDaily = (rental: Booking, startDate: Date, endDate: Date | null, now: Date) =>
            rental.status === StatusType.COMPLETED ||
            rental.status === StatusType.CANCELLED ||
            (rental.status === StatusType.ACTIVE && endDate && endDate <= now)

        const isPastOnMonthly = (rental: Booking, startDate: Date, endDate: Date | null, now: Date) =>
            isPastOnDaily(rental, startDate, endDate, now) || (rental.status === StatusType.ACTIVE && !endDate)

        const now = new Date()
        const endDate = rental.handbackTime && new Date(rental.handbackTime)
        const startDate = new Date(rental.handoverTime)

        if (
            statusIsActiveOnDaily(rental, startDate, endDate, now) ||
            statusIsActiveOnMonthly(rental, startDate, endDate, now)
        ) {
            return BookingTab.active
        }
        if (
            rental.status === BookingStatus.pending_approval ||
            (rental.status === BookingStatus.approved && startDate > now)
        ) {
            return BookingTab.upcoming
        }
        if (isPastOnDaily(rental, startDate, endDate, now) || isPastOnMonthly(rental, startDate, endDate, now)) {
            return BookingTab.past
        }
        return false
    }, [])

    const getBookingsForStatus = useCallback(
        (status: BookingTab) =>
            (rentals ?? []).filter((rental: any) => {
                return checkRentalStatus(rental) == status
            }),
        [checkRentalStatus, rentals],
    )

    return { getBookingsForStatus, rentals }
}
