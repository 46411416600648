export const LoginDesktop = () => {
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.6055 0C5.08547 0 0.605469 4.48 0.605469 10C0.605469 15.52 5.08547 20 10.6055 20C16.1255 20 20.6055 15.52 20.6055 10C20.6055 4.48 16.1255 0 10.6055 0ZM10.6055 3C12.2655 3 13.6055 4.34 13.6055 6C13.6055 7.66 12.2655 9 10.6055 9C8.94547 9 7.60547 7.66 7.60547 6C7.60547 4.34 8.94547 3 10.6055 3ZM10.6055 17.2C8.10547 17.2 5.89547 15.92 4.60547 13.98C4.63547 11.99 8.60547 10.9 10.6055 10.9C12.5955 10.9 16.5755 11.99 16.6055 13.98C15.3155 15.92 13.1055 17.2 10.6055 17.2Z"
                fill="#FF5A5A"
            />
        </svg>
    )
}
