export const MyRentalNavigationIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.75 9.12491L17.25 10.6249L16.125 7.84991C16.0189 7.56591 15.8292 7.32073 15.581 7.14667C15.3328 6.97262 15.0377 6.87787 14.7345 6.87491H9.3C8.99438 6.86789 8.69391 6.95442 8.43883 7.12291C8.18375 7.2914 7.98624 7.5338 7.87275 7.81766L6.75 10.6249L5.25 9.12491"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M8.25 13.625H8.2575"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M15.75 13.625H15.7575"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M17.25 10.625H6.75C5.92157 10.625 5.25 11.2966 5.25 12.125V15.125C5.25 15.9534 5.92157 16.625 6.75 16.625H17.25C18.0784 16.625 18.75 15.9534 18.75 15.125V12.125C18.75 11.2966 18.0784 10.625 17.25 10.625Z"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M6.75 16.625V18.125"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M17.25 16.625V18.125"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
