import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { CarImage } from '@components/modules/Car/CarImage'
import { ListingsResponseData } from '@service/booking.types'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { formatPrice } from '@util/config'
import { convertToTranslationKey } from '@util/functions'
import { TranslationPrefix } from '@util/enums'
import { useGlobalState } from '@contexts/global'
import { ProductType } from '@service/configuration.types'
import { CarTag } from '@global/CarTag'
import { device } from '@util/responsive'
import { useCarUtils } from '@hooks/useCarUtils'
import { useCommon } from '@contexts/common'

type CarCardProps = {
    car: ListingsResponseData
    preload?: boolean
}

export const MobileCarCard: FC<CarCardProps> = ({ car, preload }) => {
    const { t } = useTranslation()
    const { push, query } = useRouter()
    const { bookingState } = useGlobalState()
    const { nameModelGeneration } = useCarUtils(car)
    const { handlerFullScreenLoaderState } = useCommon()
    const [loading, setLoading] = useState(false)

    const { currency } = car.pricingDetails
    const currencyTranslated = t(
        convertToTranslationKey(currency, TranslationPrefix.currency),
        currency,
    ).toLocaleUpperCase()

    const handleClick = async () => {
        setLoading(true)
        handlerFullScreenLoaderState(true)
        await push(`/${query?.city}/cars/${car.id}`)
        setLoading(false)
        handlerFullScreenLoaderState(false)
    }

    if (!car.pricingDetails.periodPrice) {
        return null
    }

    return (
        <CarCardContainer data-testid={`car-card_${car.id}`} onClick={handleClick}>
            {loading && <Overlay />}
            <CarDataWrapper>
                <CarTitleWrapper>
                    <CarHeading color="rgba(0, 0, 0, 1)">{nameModelGeneration}</CarHeading>
                </CarTitleWrapper>
                <CarPrices>
                    <CarPeriodPrice
                        className="priceText"
                        color={'var(--primaryColor)'}
                        fontWeigth={'var(--weight-extraBold)'}
                    >
                        <b data-testid="dailyPrice">
                            {t('carCardPrice', '{{ amount }} {{currency}}', {
                                amount: formatPrice(car.pricingDetails.periodPrice),
                                currency: currencyTranslated,
                            })}
                        </b>{' '}
                    </CarPeriodPrice>
                    <CardTextWrapper className="m-start-5px">
                        {bookingState.productType === ProductType.DAILY
                            ? t('carCardPerDay', ' / day')
                            : t('carCardPerMonth', ' / month')}
                    </CardTextWrapper>
                </CarPrices>
            </CarDataWrapper>
            <CarImgWrapper>
                <CarImage src={car.coverPhoto} preload={preload} />
                <CarTagWrapper>
                    {car.tags.map((carTag) => (
                        <CarTag
                            key={carTag.id}
                            backgroundColor={`#${carTag.backgroundHexCode}`}
                            color={carTag.isLabelDark ? '#000000' : '#ffffff'}
                        >
                            {t(carTag.i18nKey)}
                        </CarTag>
                    ))}
                </CarTagWrapper>
            </CarImgWrapper>
            <CarFeaturesBg>
                <CarFeaturesWrapper>
                    <CarProperty>{t(`carProperty.${car.gearboxType.toLowerCase()}`, '')}</CarProperty>
                    <CarProperty>{t(`carProperty.${car.carType.toLowerCase()}`, '')}</CarProperty>
                    <CarProperty>
                        {t('carDetailsFeatureBadgePassengerCount', '{{ passengerCount }} person', {
                            passengerCount: car.seats,
                        })}
                    </CarProperty>
                    {car.engineSize && <CarProperty>{car.engineSize}</CarProperty>}
                </CarFeaturesWrapper>
            </CarFeaturesBg>
        </CarCardContainer>
    )
}

const CarProperty = styled.div`
    white-space: nowrap;
    background: #ebf1f7;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    margin: 0 10px 0 0;
    font-weight: 500;
    font-size: 13px;
`

const CarTagWrapper = styled.div`
    display: inline-flex;
    gap: 5px;
    position: absolute;
    top: 8.5px;
    right: 5px;
    white-space: nowrap;
    flex-wrap: wrap;
`

const CarCardContainer = styled.div`
    background: white;
    margin-top: var(--padding);
    // box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 0 12px 0;
    border-radius: 16px;
    border: 1px solid #e0e0e3;
    position: relative;
    z-index: 10;
`

// New Overlay styled component
const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #e0eaf7;
    opacity: 0.7;
    z-index: 20; /* Higher than other elements to ensure it's on top of the card */
`

const CarDataWrapper = styled.div`
    border-radius: 16px 16px 0 0;
    padding: 16px 20px 0;
    display: flex;
    justify-content: space-between;
    // position: relative;
`

const CarImgWrapper = styled.div`
    width: 100%;
    padding: 0px;
    margin: 0 0 -5px;
    overflow: hidden;
    position: relative;
`

const CarTitleWrapper = styled.div`
    max-width: 50%;
`

const CarHeading = styled.h3<{ color?: string; fontWeigth?: string }>`
    font-size: calc(var(--size-16) + 4 * ((100vw - 320px) / 55));
    color: ${(props) => props.color || ''};
    font-weight: ${(props) => props.fontWeigth || ''};
    margin: 0 0 10px 0;

    @media ${device.mobileM} {
        font-size: var(--size-20);
    }
`

const CarPrices = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 10px;
`

const CarPeriodPrice = styled.h3<{ color?: string; fontWeigth?: string }>`
    font-size: calc(var(--size-12) + 4 * ((100vw - 320px) / 55));
    color: ${(props) => props.color || ''};
    font-weight: ${(props) => props.fontWeigth || ''};
    margin: 0;

    @media ${device.mobileM} {
        font-size: var(--size-20);
    }

    @media ${device.mobileL} {
        font-size: var(--size-20);
    }

    & > span {
        font-size: calc(var(--size-12) + 2 * ((100vw - 320px) / 55));
        font-weight: var(--weight-regular);

        @media ${device.mobileM} {
            font-size: var(--size-14);
        }
    }
`

const CarFeaturesBg = styled.div``
const CarFeaturesWrapper = styled.div`
    border-radius: 0 0 16px 16px;
    margin: 0 0 0 12px;
    padding: 1rem 0 0 0;
    display: flex;
    flex-wrap: wrap;
    row-gap: 0.5rem;
`
const CardTextWrapper = styled.span`
    color: var(--primaryColor);
`
