import { debounce } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'

export const useScroll = (offsetInPixel = 0) => {
    const [isScrolled, setScrolled] = useState(false)
    const [scrollDirection, setScrollDirection] = useState<'down' | 'up'>('up')
    const scrollYRef = useRef(window.scrollY)

    const checkScroll = useCallback(() => {
        const direction = window.scrollY > scrollYRef.current ? 'down' : 'up'
        if (direction === 'down') {
            document.body.classList.add('scrolled')
        } else {
            document.body.classList.remove('scrolled')
        }
        const isScrolledTreshold = Math.abs(window.scrollY - scrollYRef.current) > 1
        setScrolled(window.scrollY - offsetInPixel > 0)
        if (direction !== scrollDirection && isScrolledTreshold) {
            setScrollDirection(direction)
            scrollYRef.current = window.scrollY
        }
    }, [scrollDirection, offsetInPixel])

    const debouncedCheckScroll = debounce(checkScroll, 10)

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', debouncedCheckScroll)
            return () => {
                window.removeEventListener('scroll', debouncedCheckScroll)
            }
        }
        return
    }, [debouncedCheckScroll])
    return { isScrolled, scrollDirection }
}
