import { ListingByIdResponse, ListingsResponseData } from '@service/booking.types'
import { useTranslation } from 'react-i18next'

type UseCarUtilsResponse = {
    nameModelGeneration: string
    nameModel: string
    generation: string
}

export const useCarUtils = (car?: ListingsResponseData | ListingByIdResponse): UseCarUtilsResponse => {
    const { t } = useTranslation()
    const nameModel = car
        ? `${t(car.makeI18nKey || car.make, car.make)} ${t(car.modelI18nKey || car.model, car.model)}`
        : ''
    const generation =
        !!car && !!car.generation && car.generation.trim() !== '-' ? `${t(car.generation, car.generation)}` : ''

    return {
        nameModelGeneration: [nameModel, generation].filter(Boolean).join(' '),
        nameModel: nameModel,
        generation,
    }
}
