import { FC, useRef, useState } from 'react'
import { useClickOutside } from '@hooks/clickOutside'
import styled from 'styled-components'
import { device } from '@util/responsive'
import { Poi } from './icons/Poi'
import { useTranslation } from 'next-i18next'
import {
    MobileDropDown,
    MobileDropDownContentContainer,
    MobileDropDownIconContainer,
    MobileDropdownItem,
    MobileDropDownItemContainer,
    MobileDropDownMenuText,
    NavIconAndLink,
} from '@global/styled'
import { ArrowIcon } from '@global/icons/Arrow'
import { type CityItem, useCitySelector } from './useCitySelector'
import { useRouter } from '@hooks/useRouter'
import { useLanguage } from '@hooks/useLanguage'
import { getUtsLanguageCodeByCityCodeAndLanguageIsoCode } from '@util/localization'

type CitySwitcherProps = {
    isDesktop?: boolean
    setIsSwitcherOpen?: (isOpen: boolean) => void
}

const CitySwitcher: FC<CitySwitcherProps> = ({ isDesktop, setIsSwitcherOpen }) => {
    const { citySelector } = useCitySelector()
    const { currentLanguage } = useLanguage()
    const [isOpen, setIsOpen] = useState(false)
    const citySelectorRef = useRef(null)
    const { t } = useTranslation()

    const { asPath, query, push } = useRouter()
    const currentCity = query.city as string

    useClickOutside(citySelectorRef, () => {
        setIsOpen(false)
    })

    if (!currentCity) return null

    const createUrlForCity = (selectedCity: string) => {
        const currentCity = query.city as string
        if (asPath.includes('car')) {
            const index = asPath.indexOf('cars')
            const initialUrl = `${asPath.substring(0, index)}cars`
            return initialUrl.replace(currentCity, selectedCity)
        }
        return asPath.replace(currentCity, selectedCity)
    }

    const handleItemClick = (selectedCity: CityItem) => {
        const locale = getUtsLanguageCodeByCityCodeAndLanguageIsoCode(
            selectedCity.locale,
            currentLanguage.languageIsoCode,
        )

        push(
            {
                pathname: createUrlForCity(selectedCity.value),
                query,
            },
            undefined,
            { shallow: true, locale, scroll: false },
        )

        setIsOpen(false)
    }

    const DesktopCitySwitcher = (
        <div ref={citySelectorRef}>
            <NavIconAndLink
                data-testid="navigation_city-switcher_current-city"
                isDisabled={!citySelector.enabled}
                onClick={() => {
                    citySelector.enabled && setIsOpen(!isOpen)
                }}
            >
                <Poi />
                <p>{t(currentCity)}</p>
            </NavIconAndLink>
            {isOpen && (
                <DropDownMenu>
                    {citySelector.cities.map((city: CityItem) => {
                        if (city.value === currentCity) {
                            return null
                        }
                        return (
                            <CityItem
                                isDisabled={!city.enabled}
                                key={city.value}
                                onClick={() => city.enabled && handleItemClick(city)}
                                data-testid={`navigation_city-switcher_${city.value}`}
                            >
                                {city.text}
                            </CityItem>
                        )
                    })}
                </DropDownMenu>
            )}
        </div>
    )

    const MobileCitySwitcher = (
        <MobileDropDown>
            <MobileDropDownContentContainer onClick={() => setIsSwitcherOpen && setIsSwitcherOpen(false)}>
                <MobileDropDownItemContainer>
                    <MobileDropdownItem>
                        <MobileDropDownIconContainer>
                            <ArrowIcon rotate={90} color="var(--primaryIconColor)" />
                        </MobileDropDownIconContainer>
                        <MobileDropDownMenuText>{t('menuItem.back', 'Back')}</MobileDropDownMenuText>
                    </MobileDropdownItem>
                </MobileDropDownItemContainer>
                {citySelector.cities.map((city: CityItem) => {
                    if (city.value === currentCity) {
                        return null
                    }
                    return (
                        <MobileDropDownItemContainer
                            key={city.value}
                            onClick={() => city.enabled && handleItemClick(city)}
                        >
                            <MobileDropdownItem isDisabled={!city.enabled}>
                                <MobileDropDownIconContainer>
                                    <Poi />
                                </MobileDropDownIconContainer>
                                <MobileDropDownMenuText> {city.text}</MobileDropDownMenuText>
                            </MobileDropdownItem>
                        </MobileDropDownItemContainer>
                    )
                })}
            </MobileDropDownContentContainer>
        </MobileDropDown>
    )

    return isDesktop ? DesktopCitySwitcher : MobileCitySwitcher
}

const CityItem = styled.p<{ isDisabled?: boolean }>`
    cursor: pointer;
    white-space: nowrap;
    color: ${({ isDisabled }) => isDisabled && '#c7c7c7'};
`

const DropDownMenu = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    background-color: white;
    top: 0;
    border-radius: var(--borderRadius);
    position: absolute;
    padding: 16px;
    box-shadow: var(--boxShadow);
    z-index: 100;
    @media ${device.laptop} {
        z-index: 100;
    }
`

export default CitySwitcher
