import styled from 'styled-components'

const PoiSvg = styled.svg<{ isOpen?: boolean }>`
    color: ${(props) =>
        props.isOpen
            ? `var(--primaryColor)`
            : `rgba(0, 0, 0, 0.8)
`};
`

export const Poi = ({ isOpen }: { isOpen?: boolean }) => (
    <PoiSvg width="14" height="20" viewBox="0 0 14 20" fill="black" xmlns="http://www.w3.org/2000/svg" isOpen={isOpen}>
        <path
            d="M7 0C3.13 0 0 3.13 0 7C0 11.17 4.42 16.92 6.24 19.11C6.64 19.59 7.37 19.59 7.77 19.11C9.58 16.92 14 11.17 14 7C14 3.13 10.87 0 7 0ZM7 9.5C5.62 9.5 4.5 8.38 4.5 7C4.5 5.62 5.62 4.5 7 4.5C8.38 4.5 9.5 5.62 9.5 7C9.5 8.38 8.38 9.5 7 9.5Z"
            fill="currentColor"
        />
    </PoiSvg>
)
