export const Cross: React.FC<{ className?: string }> = ({ className }) => {
    return (
        <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="black"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M13.3 1.21C12.91 0.820001 12.28 0.820001 11.89 1.21L6.99997 6.09L2.10997 1.2C1.71997 0.810001 1.08997 0.810001 0.699971 1.2C0.309971 1.59 0.309971 2.22 0.699971 2.61L5.58997 7.5L0.699971 12.39C0.309971 12.78 0.309971 13.41 0.699971 13.8C1.08997 14.19 1.71997 14.19 2.10997 13.8L6.99997 8.91L11.89 13.8C12.28 14.19 12.91 14.19 13.3 13.8C13.69 13.41 13.69 12.78 13.3 12.39L8.40997 7.5L13.3 2.61C13.68 2.23 13.68 1.59 13.3 1.21Z"
                fill="currentColor"
            />
        </svg>
    )
}
