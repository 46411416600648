export const MiniArrowLeft = () => {
    return (
        <svg
            className="iconSvgArrow"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.08 5.99329C14.7534 5.66662 14.2267 5.66662 13.9 5.99329L8.36004 11.5333C8.10004 11.7933 8.10004 12.2133 8.36004 12.4733L13.9 18.0133C14.2267 18.34 14.7534 18.34 15.08 18.0133C15.4067 17.6866 15.4067 17.16 15.08 16.8333L10.2534 12L15.0867 7.16662C15.4067 6.84662 15.4067 6.31329 15.08 5.99329Z"
                fill="black"
                fillOpacity="0.8"
            />
        </svg>
    )
}
