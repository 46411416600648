import { AdditionalServices, City, CityCode, MarkerInfoBoxType, PaymentOperation, ServiceKind } from '@util/enums'
import { Currency, ProductType } from './configuration.types'

export type ListingsResponse = {
    paginationInfo: PaginationInfo
    data: ListingsResponseData[]
}

export type PaginationInfo = {
    currentPage: number
    pageSize: number
    totalElements: number
    totalPages: number
}

export type ListingsResponseData = {
    id: number | string
    pricingDetails: {
        periodPrice: number
        totalRentalFee: number
        currency: string
        serviceFee: number
    }
    coverPhoto: string
    make: string
    model: string
    generation: string
    carType: CarType
    gearboxType: GearBoxType
    seats: number
    carFeatures: CarFeature[]
    engineSize: string
    makeI18nKey: string
    modelI18nKey: string
    tags: TagDto[]
    productType: ProductType
}

export type ListingFilter = {
    page?: number
    pageSize?: number
    offset?: number
    cityCode?: CityCode
    handoverTime?: Date | string
    handbackTime?: Date | string
    carType?: CarType[]
    minDailyPrice?: number
    maxDailyPrice?: number
    minSeats?: number
    maxSeats?: number
    productType?: ProductType
    carFeatureIds?: string[]
    engineTypes?: string[]
    gearboxTypes?: string[]
    makeIds?: number[]
    modelIds?: number[]
    isPaymentRevampEnabled?: boolean
}

export type CityCodeProductTypeFilter = {
    cityCode: City
    productType: ProductType
}

export type ModelsByMakesFilter = {
    cityCode: City
    productType: ProductType
    makeIds: string[] | number[]
}

export enum GearBoxType {
    MANUAL = 'MANUAL',
    AUTOMATIC = 'AUTOMATIC',
}

export enum EngineType {
    PETROL = 'PETROL',
    DIESEL = 'DIESEL',
    ELECTRIC = 'ELECTRIC',
    HYBRID = 'HYBRID',
}

export type TagDto = {
    id: number
    i18nKey: string
    backgroundHexCode: string
    isLabelDark: boolean
    createdAt: string
    createdBy: string
    updatedAt: string
    updatedBy: string
}

export type MakeDto = {
    id: number
    name: string
    createdAt: string
    updatedAt: string
    createdBy: string
    updatedBy: string
    i18nKey?: string
}

export type ModelDto = {
    id: number
    name: string
    generation?: string
    make: string
    seats: number
    createdAt: string
    updatedAt: string
    createdBy: string
    updatedBy: string
    i18nKey?: string
}

export enum CarType {
    COUPE = 'COUPE',
    CONVERTIBLE = 'CONVERTIBLE',
    CROSSOVER = 'CROSSOVER',
    MPV = 'MPV',
    HATCHBACK = 'HATCHBACK',
    SEDAN = 'SEDAN',
    STATION_WAGON = 'STATION_WAGON',
    SUV = 'SUV',
    PICKUP = 'PICKUP',
}

export type CarFeature = {
    i18nKey: string
    id: number
}

export type EngineSize = {
    value: string
}

export type ListingPricingDetails = {
    periodPrice: number
    totalRentalFee: number
    extraInsuranceFee?: number
    allowedKmsPerDay: number
    extraMileageFee: number
    currency: string
    totalRentalFeeAfterDiscount?: number
    discountAmount?: number
    serviceFee?: number
}

export type ListingLocation = {
    addressLine1: string
    addressLine2?: string
    d2dAvailable?: boolean
    city?: string
    country?: string
    id: number
    latitude: string
    longitude: string
    region?: string
    zipCode?: number | null
    openingHours?: OpeningHour[]
}

export type MarkerData = ListingLocation & {
    icon?: string
    infoTex?: string
    type?: MarkerInfoBoxType
    isOpen: boolean
}

export type OpeningHour = {
    closingTime: string
    createdAt: string
    createdBy: string
    dayOfWeek: string
    id: string
    openingTime: string
    updatedAt: string
    updatedBy: string
}

export type ListingPhoto = {
    id: number
    imageKey: string
    cover: boolean
    position: number
}

export type ListingByIdResponse = {
    id: number | string
    pricingDetails: ListingPricingDetails
    photos: ListingPhoto[]
    coverPhoto: string
    carFeatures: CarFeature[]
    colors: {
        id: number
        i18nKey: string
        hexCode: string
    }[]
    make: string
    model: string
    generation: string
    carType: CarType
    gearboxType: GearBoxType
    seats: number
    engineSize: string
    d2dAvailable: boolean
    location: ListingLocation | null
    tags?: TagDto[]
    supplierLocations: ListingLocation[]
    makeI18nKey: string | null
    modelI18nKey: string | null
    productType: ProductType
    packageType: string | null
}

export type BookingParamsType = {
    listingId: number
    preferredColorId: number | null
    handoverTime: string
    handbackTime: string
    handoverAddressId: number
    handbackAddressId: number
    includeExtraInsurance: boolean
    couponCode: string
    marketingMessagesAccepted: boolean
}

export type CreateBookingType = {
    productType: ProductType
    listingId: number
    preferredColorId: number | null
    handoverTime: Date | string
    handbackTime?: Date | string | null
    d2dHandoverAddress?: Address | null
    d2dHandbackAddress?: Address | null
    includeExtraInsurance: boolean
    includeSecondaryDriver: boolean
    couponCode?: string
    marketingMessagesAccepted: boolean
    selfPickupHandoverLocationId: number | null
    selfPickupHandbackLocationId: number | null
    isDoorToDoorDelivery: boolean
    isSelfPickup: boolean
}

export type CreateBookingWithPaymentMethod = {
    bookingCreate: CreateBookingType
    paymentMethodId?: number
}

export type BookingResponse = {
    bookingId: number
    paymentRefId: string
    links?: Record<string, Link>
}

type Link = {
    href: string
}

export type BookingUpdateResponse = {
    bookingId: number
    paymentRefId: string
    links?: Record<string, Link>
}

export type Address = {
    addressLine1: string
    addressLine2: string
    city: string
    region: string
    zipCode: string
    country: string
    latitude: string
    longitude: string
    id?: number
    openingHours?: OpeningHour[]
}

export type MonthlyBookingHandbackUpdateBody = {
    handbackTime: string | number
}

export type BookingHandbackUpdateBody = {
    handbackTime: string | number
    paymentOperation: PaymentOperation
}

export enum StatusType {
    PENDING_PAYMENT = 'PENDING_PAYMENT',
    PENDING_APPROVAL = 'PENDING_APPROVAL',
    APPROVED = 'APPROVED',
    ACTIVE = 'ACTIVE',
    COMPLETED = 'COMPLETED',
    CANCELLED = 'CANCELLED',
}

export enum CancelReasonTypeBeforeActive {
    FORGOT_TO_APPLY_DISCOUNT_CODE = 'I forgot to apply discount code',
    CANNOT_VERIFY_DOCUMENTS = 'Cannot verify my documents',
    WANT_TO_CHANGE_DATES = 'I want to change the dates',
    MADE_DUPLICATE_BOOKING = 'I made a duplicate booking',
    CONFIRMATION_TOO_LONG_NEED_NOW = 'Confirmation period is too long',
    OTHER = 'Other',
}

export enum CancelReasonTypeAfterActive {
    FOUND_CHEAPER = 'Found cheaper car elsewhere',
    WANT_TO_SWAP_CAR = 'I want to Swapp my car',
    DONT_NEED_ANYMORE = "I don't need a car anymore",
    NOT_SATISFIED_WITH_SERVICE = 'Not satisfied with the service',
    UNFORESEEN_CIRCUMSTANCES = 'Unforeseen circumstances',
    PLAN_CHANGED = 'My plans changed',
    OTHER = 'Other',
}

export type CancelReasonTypeKeys =
    | (keyof typeof CancelReasonTypeAfterActive | keyof typeof CancelReasonTypeBeforeActive)
    | undefined

export type CancellationReasonParam = {
    cancellationReasonType: CancelReasonTypeKeys
    message: string
}

export interface BookingAddress {
    id: number
    addressLine1: string
    addressLine2: string
    city: string
    region: string
    zipCode: string
    country: string

    latitude: string
    longitude: string
}

type CarDetails = {
    make: string
    model: string
    makeI18nKey: string | null
    modelI18nKey: string | null
}

export type Booking = {
    bookingId: number
    listingId: number
    customerId: string
    supplierId: number
    status: StatusType
    handoverTime: string
    handbackTime: string | null
    timezone: string
    includeSecondaryDriver: boolean
    d2dHandoverAddress: BookingAddress | null
    d2dHandbackAddress: BookingAddress | null
    selfPickupHandoverAddress: BookingAddress
    selfPickupHandbackAddress: BookingAddress
    selfPickupHandbackLocationId: number | null
    selfPickupHandoverLocationId: number | null
    roadsideAssistanceNumber?: string | null
    carDetails: CarDetails
    productType: ProductType
    updatePaymentMethod: boolean
    paymentDetails: {
        lastFourDigits: string | null
        description: string | null
        cardProvider: string | null
    }
}
export type CustumerBookingDocument = {
    bookingId: number
    documentKey: string
    comment: string | null
    createdBy: string
    createdAt: string
}
export type DateCalculation = {
    firstAvailableHandbackDate: string
    firstAvailableHandoverDate?: string
    unavailableDates: string[]
}
export type DateCalculationBody = {
    selfPickupHandoverLocationId: number | null
    selfPickupHandbackLocationId: number | null
}

export type MyBookingsFilter = Partial<{
    statuses: StatusType[]
    limit: number
    sortDirection: 'ASC' | 'DESC'
    sortBy:
        | 'CANCELLATION_REASON'
        | 'NOTES'
        | 'HANDOVER_MESSAGE_SENT_AT'
        | 'TEMPORARY_HANDBACK_TIME'
        | 'HANDOVER_TIME'
        | 'CREATED_AT'
        | 'CREATED_BY'
        | 'HANDOVER_AT'
        | 'STATUS'
        | 'INCLUDE_EXTRA_INSURANCE'
        | 'HANDBACK_TIME'
        | 'UPDATED_AT'
        | 'LICENSE_PLATE'
        | 'HANDBACK_MESSAGE_SENT_AT'
        | 'UPDATED_BY'
        | 'HANDBACK_AT'
        | 'DELETED_BY'
        | 'COUPON_CODE'
        | 'INCLUDE_SECONDARY_DRIVER'
        | 'DELETED_AT'
        | 'TIMEZONE'
}>

export type PriceCalculationBody = {
    supplierId?: number
    rentalPeriod: {
        startDate: string
        endDate?: string | null
    }
    productType: ProductType
    couponCode?: string
    additionalServices: AdditionalServices[]
    cityCode: CityCode
}

export type Service = {
    quantity: number
    basePrice: number
    netTotal: number
    grossTotal: number
    serviceKind: ServiceKind
    serviceType: AdditionalServices
}

export type ListingPrices = {
    externalListingId: number
    vatPercentage: number
    dailyPrice: number
    monthlyPrice?: number
    firstCharge: number
    totalRentalFee: number
    discountAmount: number
    totalRentalFeeAfterDiscount: number
    couponDetails: {
        code: string
        valid: boolean
        errors: [
            {
                type: string
                parameter: any
            },
        ]
        description: string
        discountAmount: number
        discountRate: number
        discountAmountLimit: number
        minRentalPeriod: number
        maxRentalPeriod: number
        minRentalFee: number
        minMonthlyRentalFee: number
        onlyNewCustomers: boolean
        onlyCareem: boolean
        onlyCustomersWithoutBookingsInTheLastXMonths: number
        couponSponsor: string
        sponsorPercentage: number
        supplierSponsorName: string
        supplierSponsorId: string
    }
    extraInsuranceFee?: number
    totalExtraInsuranceFee?: number
    secondaryDriverFee: number
    totalSecondaryDriverFee: number
    deliveryFee: number
    totalDeliveryFee?: number
    serviceFee: number
    totalDeposit?: number
    surchargeExtraKmsPerUnit?: number
    allowedKmsPerYear?: number
    allowedKmsPerDay?: number
}

export type PriceCalculationResponse = {
    currency: Currency
    cityCode: CityCode
    handoverTime: Date | string
    handbackTime: Date | string
    rentalPeriodDays: number
    freeCancellationEndsAt: string
    listings: ListingPrices[]
    includeExtraInsurance: boolean
    includeDelivery: boolean
    includeSecondaryDriver: boolean
}
