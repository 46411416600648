import { displayWarning, useWarning } from '@contexts/warnigContext'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { Cross } from './icons/Cross'
import { useMediaQuery } from '@hooks/mediaQuery'
import { device } from '@util/responsive'

export const WarningPanel = ({ show }: { show: boolean }) => {
    const { t } = useTranslation()
    const { query } = useRouter()
    const { dispatch } = useWarning()
    const isDesktop = useMediaQuery(device.laptop)
    const city = query.city

    if (!show) return null

    return (
        <Wrapper marginTop={isDesktop ? '0' : ''}>
            <h3>{t('bookingConfirmationHeadlineForVerify', 'You need to verify yourself first')}</h3>
            <p>
                {t('warningMissingUploadDocumentsBody', 'Please upload your missing documents for verification.')}
                <br />
                <Link href={`/${city}/verification`}>
                    {t('warningMissingUploadDocumentsLinkText', 'Click here to start verification flow')}
                </Link>
            </p>
            <CloseButton onClick={() => dispatch(displayWarning(false))}>
                <Svg />
            </CloseButton>
        </Wrapper>
    )
}

const Svg = styled(Cross)``

const CloseButton = styled.div``

const Wrapper = styled.div<{ marginTop?: string }>`
    background: var(--warning);
    padding: 10px;
    margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}` : 'var(--navMobileHeight)')};
    position: relative;

    h3 {
        margin: 0;
        font-size: 15px;
    }

    p {
        margin: 0;
        font-size: 12px;
    }

    ${Svg} {
        position: absolute;
        top: 15px;
        right: 15px;
    }
`
