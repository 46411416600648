import React from 'react'
import styled from 'styled-components'
import { SpinnerWrapper } from '@components/modules/Cars/CarList'
import { BarSpinner } from './icons/SpinnerBar'

// Full-screen overlay styled component
const FullScreenOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* High z-index to be above all components */
`

// Spinner box styled component
const SpinnerBox = styled.div`
    background-color: var(--white);
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const FullScreenLoader = ({
    width = 50,
    height = 50,
    spinnerColor = 'var(--primaryColor)',
}: {
    marginTopBottom?: number
    width?: number
    height?: number
    spinnerColor?: string
}) => (
    <FullScreenOverlay>
        <SpinnerBox>
            <SpinnerWrapper marginTopBottom={0} direction="column" align="center">
                <BarSpinner color={spinnerColor} width={width} height={height} />
            </SpinnerWrapper>
        </SpinnerBox>
    </FullScreenOverlay>
)
