const LogoRTL: React.FC = () => {
    return (
        <svg height="30" viewBox="0 0 1320 600">
            <path
                fill="#ff5a5a"
                d="M539.3,447.9H573c12.8,0,22.5-9.8,22.5-22.5V25.3c0-12.8-10.5-23.3-23.3-23.3H540c-12.8,0-23.3,10.5-23.3,23.3
	v400.1C516.7,438.1,526.5,447.9,539.3,447.9z M717.9,306.8c0-32.3,22.5-54,45.8-54c36.8,0,54,26.3,54,59.3v52.5h-39
	C741.2,364.6,717.9,338.3,717.9,306.8z M1069.2,441.9c45,0,61.6-26.3,69.1-37.5c13.5,21.8,39,37.5,79.6,37.5h74.3
	c12.8,0,23.3-10.5,23.3-23.3V203.2c0-12.8-10.5-23.3-23.3-23.3h-19.5c-12.8,0-23.3,10.5-23.3,23.3v98.3c0,33-10.5,60.1-40.5,60.1
	c-30,0-42.8-27-42.8-58.6v-60.8c0-12.8-10.5-23.3-23.3-23.3H1127c-12.8,0-23.3,10.5-23.3,23.3v65.3c0,24-5.3,54-35.3,54
	c-27.8,0-38.3-24.8-38.3-51v-68.3c0-12.8-10.5-23.3-23.3-23.3h-18.8c-12.8,0-23.3,10.5-23.3,23.3v65.3c0,34.5-12.8,54-47.3,54h-29.3
	V203.2c0-12.8-10.5-23.3-23.3-23.3H764.5c-83.3,0-125.4,63.1-125.4,130.6c0,74.3,55.5,129.9,139.6,129.9h36
	c0,46.5-39,80.3-84.8,80.3c-24,0-39.8-4.5-54-9c-12.8-3.8-25.5,2.3-29.3,14.3l-8.3,27c-3.8,12,0.8,24,12,28.5
	c25.5,9.8,50.3,16.5,87.1,16.5c78.8,0,146.4-56.3,146.4-156.1h27.8c45,0,61.6-26.3,69.1-37.5c13.5,21.8,39,37.5,79.6,37.5H1069.2z
	 M281,541c0-30.8-21-45-51.8-45c-29.3,0-53.3,15-53.3,44.3c0,30,23.3,46.5,53.3,46.5C260,586.7,281,571.7,281,541z M224,452.4h189.2
	c18.8,0,33-11.3,33-28.5V203.2c0-12.8-10.5-23.3-23.3-23.3h-31.5c-12.8,0-23.3,10.5-23.3,23.3V342c0,20.3-10.5,30.8-30.8,30.8H231.5
	c-98.3,0-155.4-21-146.4-112.6l1.5-15.8c1.5-13.5-7.5-23.3-20.3-24.8l-30-3c-12.8-1.5-23.3,6.8-24.8,20.3l-5.3,42
	C-10.2,411.1,93.4,452.4,224,452.4z"
            />
        </svg>
    )
}

export default LogoRTL
