import { config } from './config'
import { createQueryParams } from './functions'

type ImageUrlParams = {
    width: number
    height?: number
    aspectRatio?: string
    fit?: string
    auto?: string
}

export const getImageUrl = (
    imageKey: string,
    params: ImageUrlParams,
    additionalProps?: Record<string, string | string[]>,
) => {
    if (imageKey.startsWith(config.IMAGIN_STUDIOS_BASE_URL)) {
        return imageKey + `&width=${params.width}`
    }

    const imageParams = createQueryParams({
        w: params.width.toString(),
        h: params.height?.toString() || '',
        ar: params.aspectRatio || '',
        fit: params.fit || '',
        auto: params.auto || '',
        ...additionalProps,
    })

    return `${config.IMAGEX_URL}/${imageKey}?${imageParams.toString()}`
}
