import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo } from 'react'
import { useUpdatePreferredLanguage } from '@hooks/api/identity'
import { useLocaleConfig } from '@contexts/config'
import { useAuth } from '@contexts/auth'
import { getLocaleDefinitionByUtsLanguageCode } from '@util/localization'
import { CityCode, LanguageISOCode, LanguageUtsCode } from '@util/enums'

export const useLanguage = () => {
    const { cityLanguages } = useLocaleConfig()
    const { user } = useAuth()
    const isLoggedIn = !!user
    const supportedLanguages = cityLanguages.map((lang) =>
        getLocaleDefinitionByUtsLanguageCode(lang as LanguageUtsCode),
    )

    const { query, push, pathname, asPath, locale } = useRouter()

    const currentLanguage = useMemo(() => getLocaleDefinitionByUtsLanguageCode(locale as LanguageUtsCode), [locale])

    const currentCityCode = useMemo(() => (locale?.split('-')?.[1] as CityCode) || CityCode.DXB, [locale])

    const { mutateAsync: updatePreferredLanguage } = useUpdatePreferredLanguage()

    useEffect(() => {
        document.cookie = `NEXT_LOCALE=${currentLanguage.languageUtsCode}; path=/;`
    }, [currentLanguage])

    return {
        changeLanguageTo: useCallback(
            async (lang: LanguageISOCode) => {
                const newLocale = `${lang}-${currentCityCode}`
                const newLang = getLocaleDefinitionByUtsLanguageCode(newLocale)
                if (newLang && newLang.serverLanguageCode) {
                    if (isLoggedIn) {
                        await updatePreferredLanguage(newLang.serverLanguageCode)
                    }
                    push({ pathname, query }, asPath, { locale: newLocale })
                }
            },
            [asPath, currentCityCode, isLoggedIn, pathname, push, query, updatePreferredLanguage],
        ),
        currentLanguage,
        currentCityCode,
        supportedLanguages,
    }
}
