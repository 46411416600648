export const LocationNavigationIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.0002 6.24997C9.92916 6.24997 8.25023 7.9289 8.25023 9.99997C8.25023 12.071 9.92916 13.75 12.0002 13.75C14.0713 13.75 15.7502 12.071 15.7502 9.99997C15.7502 7.9289 14.0713 6.24997 12.0002 6.24997ZM9.75023 9.99997C9.75023 8.75733 10.7576 7.74997 12.0002 7.74997C13.2429 7.74997 14.2502 8.75733 14.2502 9.99997C14.2502 11.2426 13.2429 12.25 12.0002 12.25C10.7576 12.25 9.75023 11.2426 9.75023 9.99997Z"
                fill="#282D33"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.1002 3.16477C13.5895 1.60458 10.4109 1.60458 7.90026 3.16478C4.11475 5.51721 3.08074 10.5657 5.63661 14.2169L10.8272 21.6321C11.3972 22.4464 12.6032 22.4464 13.1732 21.6321L18.3638 14.217C20.9197 10.5657 19.8857 5.5172 16.1002 3.16477ZM8.69198 4.43882C10.7178 3.1799 13.2826 3.1799 15.3084 4.43881C18.363 6.33697 19.1973 10.4106 17.135 13.3568L12.0002 20.6921L6.86546 13.3567C4.80314 10.4106 5.63748 6.33698 8.69198 4.43882Z"
                fill="#282D33"
            />
        </svg>
    )
}
